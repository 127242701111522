import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import SkuTile from "../../components/SkuTile"
import Image from "../../components/Image"

import styles from "./story.module.scss"

const SS21 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "intuition-vitello-sandals",
      "guiding-light-nappa-sandals",
      // "astra-suede-flats",
      "north-star-nappa-sandals",
      "figure-eight-vitello-sandals",
    ].map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <>
      <SEO
        title="Rope"
        description="Spring Summer '21"
        url={location.pathname}
      />

      <header className={styles.header}>
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          Rope
        </Heading>
        {/*<Filters*/}
        {/*  allItems={collectionStyles}*/}
        {/*  items={filteredStyles}*/}
        {/*  setItems={setFilteredStyles}*/}
        {/*  setFilteredColor={setFilteredColor}*/}
        {/*  collectionTitle={productCollection.title}*/}
        {/*/>*/}
      </header>

      <ul className={styles.grid}>
        {allSkus.map((sku, i) => (
          <SkuTile
            key={i}
            index={i}
            style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
            sku={sku}
            customStyles={styles}
            origin={`collection-ss21`}
          />
        ))}
        <li className={styles.storyItem31}>
          <Image
            image={{
              desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Rope_1_1320x.jpg?v=1614815974",
              mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Rope_1_850x.jpg?v=1614815974",
            }}
            altText="Rope Story"
          />
        </li>
        <li className={styles.storyItem32}>
          <Image
            image={{
              desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Rope_2_1320x.jpg?v=1614815974",
              mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Rope_2_850x.jpg?v=1614815974",
            }}
            altText="Rope Story"
          />
        </li>
        <li className={styles.storyItem33}>
          <Image
            image={{
              desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Rope_3_fbd27dcb-866d-425a-b1e9-338ff083a6f5_1320x.jpg?v=1615107984",
              mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Rope_3_fbd27dcb-866d-425a-b1e9-338ff083a6f5_850x.jpg?v=1615107984",
            }}
            altText="Rope Story"
          />
        </li>
      </ul>
    </>
  )
}

export default SS21

export const query = graphql`
    query RopeQuery {
        allProductStyle(filter: {handle: {in: [
            "guiding-light-nappa-sandals",
#            "astra-suede-flats",
            "north-star-nappa-sandals",
            "figure-eight-vitello-sandals",
            "intuition-vitello-sandals",
        ]}}) {
            nodes {
                ...PLPStyleFragment
            }
        }
    }
`
